<template>
  <div class="pin">
    <h1 class="text-light">{{ $t('auth.enter-6-digits') }}?</h1>
    <Counter />
    <form method="post" v-on:submit.prevent="checkPin" name="pin-checker">
      <div style="display: flex; flex-direction: row;">
        <v-otp-input
          v-model="pin_code"
          ref="otpInput"
          input-classes="otp-input"
          separator=" "
          :num-inputs="6"
          class="pin-input mt-5"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />
      </div>
      <span class="small text-danger">{{ error }}</span>

      <div class="d-flex align--center justify--center mt-4">
        <half-circle-spinner
          slot="loading"
          :animation-duration="1500"
          :size="25"
          color="#74b9ff"
          v-if="formLoading"
          class="mx-3"
        />
        <button
          v-else
          type="submit"
          class="btn btn-primary btn-sm btn-with-icon-sm btn-block"
        >
          <div class="btn-with-icon-content">{{ $t('auth.verify') }}</div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import Counter from './counter'
import OtpInput from '@bachdgvn/vue-otp-input'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
Vue.component('v-otp-input', OtpInput)
import auth from '../../../auth'
import { mapGetters } from 'vuex'
export default {
  name: 'forget-password',
  data() {
    return {
      pin_code: '',
      error: '',
      formLoading: ''
    }
  },
  computed: {
    ...mapGetters(['forgetEmail'])
  },
  components: {
    Counter,
    HalfCircleSpinner
  },
  methods: {
    handleOnComplete(value) {
      this.pin_code = value
    },
    handleOnChange(value) {
      this.pin_code = value
    },

    async checkPin() {
      this.formLoading = true
      try {
        const response = await auth.post('api/v1/customer/pin/checker', {
          email: this.forgetEmail,
          pin_code: this.pin_code
        })
        if (response.status == 200) {
          console.log(response.data.data.message)
          this.toast(response.data.data.message)
          this.$store.commit('setPinCode', this.pin_code)
          this.$router.push({ name: 'reset-password' })
        } else {
          this.error = response.data.data.message
        }
      } catch (e) {
        if (e.response.status == 422) {
          this.error = e.response.data.error.errors.pin_code[0]
        } else {
          this.error = response.data.data.message
        }
      }
      this.formLoading = false
    }
  }
}
</script>

<style lang="scss">
// .pin-input{
//   span{
//     display: none;
//   }
// }
.otp-input {
  width: 35px;
  height: 35px;
  padding: 5px;
  margin: 0 7px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pin {
  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    .down-container {
      display: none;
    }
  }

  h1 {
    text-align: center;
    margin-top: 0;
    font-weight: bold;
    font-size: 30px;
  }
  width: 21.375rem;

  .down-container {
    margin-top: 3.125rem;
  }
}
</style>
